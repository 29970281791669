import Router from 'vue-router';
import Vue from 'vue';
Vue.use(Router)
const vueRouter = new Router({
  base: process.env.BASE_URL,
  mode: process.env.VUE_APP_MODE,
  routes: [{
    path: '/view/:id',
    name: 'view',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/view')
  }]
})
export default vueRouter;


// routes: [{
//   path: '/',
//   name: 'list',
//   component: () =>
//     import( /* webpackChunkName: "page" */ '@/page/index')
// }, {
//   path: '/create',
//   name: 'create',
//   component: () =>
//     import( /* webpackChunkName: "page" */ '@/page/create')
// }, {
//   path: '/build/:id',
//   name: 'build',
//   component: () =>
//     import( /* webpackChunkName: "page" */ '@/page/build')
// }, {
//   path: '/view/:id',
//   name: 'view',
//   component: () =>
//     import( /* webpackChunkName: "page" */ '@/page/view')
// }]